/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { SchoolLicenseWithAddtitionalLicense } from "../api/school-license";
import { isEmpty } from "lodash";
import { SupportedLanguage } from "./../components/IntlHandler/IntlHelper";

/**
 * Based on https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
 * @returns function to revoke download url
 */
export const downloadFile = (
  filename: string,
  downloadUrl: string
): (() => void) => {
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();

  return () => {
    URL.revokeObjectURL(downloadUrl);
  };
};

/**
 * Returns a string like YYYYMMDDHHmmss
 */
export const dateToString = (date: Date): string => {
  const year = date.getFullYear().toString().padStart(4, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const formatUTCDateToTimePartAndDatePart = (
  inputDate: string
): string | undefined => {
  if (inputDate.toString() === "Invalid Date") {
    return;
  }
  const dateObject = new Date(inputDate);

  const hours = String(dateObject.getUTCHours()).padStart(2, "0");
  const minutes = String(dateObject.getUTCMinutes()).padStart(2, "0");
  const seconds = String(dateObject.getUTCSeconds()).padStart(2, "0");

  const day = String(dateObject.getUTCDate()).padStart(2, "0");
  const month = String(dateObject.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-indexed
  const year = dateObject.getUTCFullYear();

  const formattedDate = `${hours}:${minutes}:${seconds} - ${day}/${month}/${year}`;

  return formattedDate;
};

export const arrayToFilename = (array: any[]) => {
  return array
    .toString()
    .toLowerCase()
    .replace("[", "")
    .replace("]", "")
    .replace(",", "_")
    .replace(" ", "");
};

const getSessionStorage = (key: string, defaultValue: string) => {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
};

export const useSessionStorage = (key: string, defaultValue: string) => {
  const [value, setValue] = useState(getSessionStorage(key, defaultValue));
  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

/**
 * Groups all items in an array of objects `T` where the value of property `K` is the same
 * Note that equality is checked by comparing the stringified versions of property `K`.
 * @param array Items to group
 * @param key Key of `T` to group by
 */
export function groupBy<T, K extends keyof T>(array: T[], key: K) {
  const stringRepresentation = (obj: any): string => {
    let stringRep = obj.toString();
    if (Array.isArray(obj)) {
      obj.sort();
      stringRep = obj.join(":");
    }
    return stringRep;
  };

  const map = new Map<string, T[]>();
  array.forEach((item) => {
    const stringKey = stringRepresentation(item[key]);
    if (!map.has(stringKey)) {
      map.set(
        stringKey,
        array.filter((i) => stringRepresentation(i[key]) === stringKey)
      );
    }
  });
  return map;
}

export function replaceAllStrings(text: string, replaceMap: any) {
  const reg = new RegExp(Object.keys(replaceMap).join("|"), "gi");
  return text.replace(reg, function (matched) {
    return replaceMap[matched.toUpperCase()];
  });
}

// Fixxxxxx
export const validateSchoolLicense = (
  schoolLicenses: SchoolLicenseWithAddtitionalLicense[]
): boolean => {
  let isSchoolLicenseValid: boolean = false;

  if (schoolLicenses && !isEmpty(schoolLicenses)) {
    for (let schoolLicense of schoolLicenses) {
      if (
        new Date().getTime() - new Date(schoolLicense.validUntil).getTime() <
        0
      ) {
        isSchoolLicenseValid = true;
      }
    }
  }
  return isSchoolLicenseValid;
};

export const formatSchoolLicenses = (
  licenseProducts: SchoolLicenseWithAddtitionalLicense[]
) => {
  let learning = [];
  let plus = [];

  for (let item of licenseProducts) {
    if (item.products[0] === "CLASSPAD_LEARNING") {
      learning.push(item);
    }

    if (item.products[0] === "CLASSPAD_NET_PLUS") {
      plus.push(item);
    }
  }
  learning.sort((a, b) => {
    return new Date(b.validUntil).valueOf() - new Date(a.validUntil).valueOf();
  });

  plus.sort((a, b) => {
    return new Date(b.validUntil).valueOf() - new Date(a.validUntil).valueOf();
  });

  const lastestCPLearing = learning[0];
  const lastestCPPlus = plus[0];

  let result: SchoolLicenseWithAddtitionalLicense[] = [];

  if (lastestCPLearing && !lastestCPPlus) {
    result = [lastestCPLearing];
  }
  if (!lastestCPLearing && lastestCPPlus) {
    result = [lastestCPPlus];
  }
  if (lastestCPLearing && lastestCPPlus) {
    result = [lastestCPLearing, lastestCPPlus];
  }

  return result;
};

export function buildSearchParams(paramsObject: { [key: string]: any }) {
  const params = [];

  for (const key in paramsObject) {
    if (
      paramsObject.hasOwnProperty(key) &&
      paramsObject[key] !== undefined &&
      paramsObject[key] !== null
    ) {
      params.push(`${key}=${encodeURIComponent(paramsObject[key])}`);
    }
  }

  return params.length > 0 ? `&${params.join("&")}` : "";
}

export function convertStatusToText(status: boolean | undefined): string {
  console.log("convertStatusToText");
  console.log("status", status);
  if (typeof status !== "boolean") {
    return "";
  }

  return status ? "Activated" : "Non Activated";
}

export const dateWithoutTimezone = (date: Date) => {
  const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  const withoutTimezone = new Date(date.valueOf() - tzoffset)
    .toISOString()
    .slice(0, -1);
  return withoutTimezone;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getTeachingLevelSelectValue = (
  value: string[]
): { value: string; label: string }[] => {
  const options = getTypesTeachingLevelAdmin("fr");

  const result = options.filter((option) => {
    if (value.includes(option.value)) return option;
  });

  return result;
};

interface SchoolType {
  readonly value: string;
  readonly label: string;
}

export const getTypesTeachingLevelAdmin = (
  language: SupportedLanguage
): SchoolType[] => {
  const types: any = getAllTypesTeachingLevelAdmin();
  return types[language];
};

const getAllTypesTeachingLevelAdmin = (): {
  de: {
    value: string;
    label: string;
  }[];
  fr: {
    value: string;
    label: string;
  }[];
  en: {
    value: string;
    label: string;
  }[];
} => {
  const germanSchoolTypes = [
    {
      value: "primary_level",
      label: "Primary",
    },
    {
      value: "secondary_school",
      label: "College",
    },
    {
      value: "secondary_school_2",
      label: "High School",
    },
    {
      value: "tutoring_school",
      label: "Tutoring",
    },
    {
      value: "second_chance_education",
      label: "Second-chance education",
    },
    {
      value: "professional_school",
      label: "professional school",
    },
    {
      value: "university",
      label: "Superior",
    },
    { value: "other", label: "Other" },
  ];

  const frenchSchoolTypes = [
    {
      value: "primary_level",
      label: "Primary",
    },
    {
      value: "secondary_school",
      label: "College",
    },
    {
      value: "secondary_school_2",
      label: "High School",
    },
    {
      value: "tutoring_school",
      label: "Tutoring",
    },
    {
      value: "university",
      label: "Superior",
    },
    { value: "other", label: "Other" },
  ];

  const schoolTypes = {
    de: germanSchoolTypes,
    fr: frenchSchoolTypes,
    en: germanSchoolTypes,
  };
  return schoolTypes;
};
